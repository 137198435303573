import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";

export default function useFamiliesList() {
  // Use toast

 

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "created_by", label: "مقدم الطلب", sortable: false },
    { key: "family_status.name", label: "طبيعة العائلة ", sortable: true },

    {
      key: "registration_date",
      label: "تاريخ التسجيل ",
      
      sortable: true,
    },
    {
      key: "current_address.neighborhood.name",
      label: "  الحي الحالي",
      
      sortable: true,
    },
    {
      key: "recognized_by_method.name",
      label: " طريقة التعرف على المركز ",
      
      sortable: true,
    },
    {
      key: "displacement_date",
      label: " تاريخ النزوح",
      
      sortable: true,
    },
    {
      key: "registration_date",
      label: " تاريخ التسجيل",
      formatter: title,
      sortable: true,
    },
  
    // { key: 'status', sortable: true },
    { key: "actions" ,label: "الخيارات"},
  ];
  const perPage = ref(10);
  const totalFamilies = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5,10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalFamilies.value,
    };
  });
  const editBarcode = (id) => {
    Vue.swal({
      title: 'ادخل رقم البطاقة الجديد',
      input: 'text',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      inputAttributes: {
        autocapitalize: 'off',
      },
    
      CancelButtonText: 'إلغاء',
      confirmButtonText: 'حفظ',
      showLoaderOnConfirm: true,
     
    }).then(result => {
    if(result.value.length <8 ){
      Vue.swal({
        title: "رقم البطاقة لا يمكن ان يكون اقل من 8 محارف",
icon: "error",
     
  confirmButtonText: "موافق",
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
});
    }
    else{
      
       
      store
      .dispatch("app-family/editbarcode", {id:id,
       number: result.value,
      })
      .then((response) => {
        Vue.swal({
          title:  `${response.data.message}`,
  
       
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });

     
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
  icon: "error",
       
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
  
       
      });
    }
      
    })
    

   
  }
  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const onFiltered=(filteredItems) =>{
    // Trigger pagination to update the number of buttons/pages due to filtering
    totalFamilies.value = filteredItems.length
    currentPage.value = 1
  }

     
  const fetchFamilies = (ctx, callback) => {
    store
      .dispatch("app-family/fetchFamilies", {search: searchQuery.value,
        page_size: perPage.value,
      page: currentPage.value,})
      .then((response) => {
   
        const { data, total } = response.data;

 //       console.log(response.data)
        callback(data);
        totalFamilies.value = response.data.meta.total;

     
      })
      .catch(() => {
        Vue.swal({
          title: "حدثت مشكلة في استرجاع البيانات",
  icon: "error",
       
    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
  
       
      });
  };

  return {
    fetchFamilies,
    onFiltered,
    tableColumns,
    perPage,
    currentPage,
    editBarcode,
    totalFamilies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  };
}
